body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  white-space: initial;
}

.fc .fc-button-primary {
  background-color: rgb(117, 128, 140) !important;
  border-color: rgb(87, 98, 109) !important;
}
.fc .fc-button-primary:hover {
  background-color: rgb(87, 98, 109) !important;
  border-color: rgb(87, 98, 109) !important;
}

.Mui-disabled {
  display: none;
}

.tox .tox-statusbar {
  padding: 20px 8px !important;
}

.tox-tbtn {
  align-items: center;
  background: 0 0;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  justify-content: center;
  margin: 2px 0 3px 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-transform: none;
  width: 34px;
  position: absolute;
  z-index: 5;
}

.quote {
  right: 35px;
}

.tire {
  right: 80px;
}

.apostrophe {
  right: 125px;
  font-size: x-large;
  vertical-align: middle;
  padding-top: 16px;
}

.tox-tbtn:hover {
  background-color: #dee0e2;
  cursor: pointer;
}
